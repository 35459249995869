.cart {
    margin: 5%;
}

.cart .title {
    font-size: 30px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 32px;
}

.cart-container {
	display: grid;
    margin: auto;
	grid-gap: 30px;
	grid-template-columns: repeat(5, minmax(0, 1fr));
}

.cart-card {
    width: 100%;
    padding: 12px;
    display: flex;
    flex-direction: column;
}

.cart-content {
    margin-left: 24px;
}

.price-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart-title {
    font-weight: 600;
    font-size: 20px;
}

.cart-description {
    font-size: 16px;
}

.cart-price {
    font-weight: 600;
}

.cart-image {
    display: flex;
    align-items: center;
    border-radius: 8px;
}

.added-cart {
    display: flex;
    padding: 4px 12px;
    background-color: #3C3E3C;
    color: #FFF;
    font-size: 24px;
    align-items: center;
}

.added-cart span:nth-child(2) {
    margin: 0 14px;
    font-size: 18px;
}

.added-cart :not(span:nth-child(2)) {
    cursor: pointer;
    font-weight: 600;
}

.cart-line {
    width: 100%;
    border: 1px solid #70707094;
    margin: 32px 0;
}

.total-cart {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.sub-total {
    display: flex;
    justify-content: space-between;
}


.total-item {
    display: flex;
    justify-content: space-between;
}

.proceed-checkout {
    font-size: 18px;
    padding: 12px 24px;
    background-color: #3C3E3C;
    color: #FFF;
    border: 1px solid #000;
    cursor: pointer;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

@media only screen and (max-width: 1192px) {
    .cart .title {
        font-size: 26px;
        margin-bottom: 28px;
    }

    .cart-container {
        grid-gap: 0px;
    }

    .cart-content {
        margin: 0;
    }

    .cart-title {
        font-size: 14px;
    }
    
    .cart-description {
        font-size: 12px;
    }

    .cart-price {
        font-size: 12px;
        margin: 0;
    }

    .added-cart {
        padding: 4px 8px;
        font-size: 12px;
    }

    .added-cart span:nth-child(2) {
        margin: 0 8px;
        font-size: 14px;
    }

    .total-cart {
        font-size: 16px;
    }

    .proceed-checkout {
        font-size: 16px;
    }
}

@media only screen and (max-width: 768px) {
    .cart .title {
        font-size: 24px;
        margin: 20px;
    }

    .cart-container {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .total-cart {
        font-size: 14px;
    }

    .proceed-checkout {
        font-size: 14px;
    }
}

@media only screen and (max-width: 413px) {
    .cart .title {
        font-size: 22px;
        margin: 20px;
    }
    
    .cart-container {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}